import { template as template_6e1f6c65a4484d0a89c6d3df9b4dd625 } from "@ember/template-compiler";
const FKText = template_6e1f6c65a4484d0a89c6d3df9b4dd625(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
