import { template as template_6ac0d5d6418b4a9196b8fb28fd191a96 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6ac0d5d6418b4a9196b8fb28fd191a96(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
