import { template as template_7a256950e16247298ed067343ea70f11 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_7a256950e16247298ed067343ea70f11(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
